import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import hash from 'object-hash';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, tap, throttleTime } from 'rxjs/operators';
import { ApiService } from 'src/app/services/api/api.service';
import { waitForItem } from 'src/app/services/api/cached-api';
import { SelectOption } from 'src/app/settings/settings.model';
import { arrayToObject, updateNestedObject } from 'src/app/shared/common';
import { log } from 'src/app/shared/log';
import {
    DashboardContent,
    DashboardContext,
    Position,
} from 'src/app/shared/models/dashboard';
import { parsePath, parseSegments } from 'src/app/shared/topic-utils';
import { Datasource, DatasourceConfig } from '../models/datasource';
import { DatasourceFactory } from '../models/datasource-factory';
import { DatasourceType } from '../models/datasource-type';
import { Pane } from '../models/pane';
import {
    Widget,
    WidgetCategory,
    WidgetType,
    WidgetTypeName,
} from '../models/widget';

@Injectable()
export class DashboardPageService {
    // Overall dashboard
    dashboardId = null;
    customerId = null;
    deviceIds = [];
    name$ = new BehaviorSubject<string>('');
    lastSavedHash = null;
    // Columns
    numColumns$ = new BehaviorSubject<number>(null);
    // Panes
    newPane$ = new BehaviorSubject<Pane>(null);
    lastPanesHash = null;
    private _panes$ = new BehaviorSubject<Pane[]>(null);
    panesUpdates$ = new BehaviorSubject<Pane[]>([]);
    private _panePositions = new Map<string, Position>();
    private _paneWidths = new Map<string, number>();
    // Datasources
    private _datasources: Datasource[] = [];
    datasourceUpdates$ = new Subject<Datasource[]>();
    lastDatasourcesHash = null;
    _datasourcesByName: { [key: string]: Datasource } = {};
    canIds$ = new BehaviorSubject<any>(null);
    private _widgetTypeNames: Record<string, string> = {};

    context: DashboardContext;

    private instantData$ = new BehaviorSubject<any>({});
    data$ = this.instantData$.pipe(throttleTime(1000));

    // The data saved to the backend.
    get dashboardData(): {
        name: string;
        customerId: number;
        content: DashboardContent;
        deviceIds: number[];
    } {
        return {
            name: this.name$.getValue(),
            customerId: this.customerId,
            content: {
                version: 1,
                numColumns: this.numColumns$.value,
                datasources: this._datasources.map((d) => d.build()),
                panes: this._panes$.value,
                panePositions: this._panePositions,
                paneWidths: this._paneWidths,
            },
            deviceIds: this.deviceIds,
        };
    }

    get busNumbers() {
        const busInfo = {};
        for (const item of this.context.canBuses) {
            busInfo[item.id] = item.name;
            busInfo[item.name] = item.id;
        }
        return busInfo;
    }

    get hasUnsavedChanges() {
        return hash(this.dashboardData) != this.lastSavedHash;
    }

    get isTabVisible() {
        return document.visibilityState == 'visible';
    }

    get widgetTypeOptions(): SelectOption[] {
        return Object.entries(this._widgetTypeNames)
            .map(([value, name]) => ({ value, name }))
            .sort((a: { name: string }, b: { name: string }) =>
                a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
            );
    }

    get widgetCategoryOptions(): SelectOption[] {
        return Object.values(WidgetCategory).map((categoryId) => ({
            name: 'widget.category.' + categoryId,
            value: categoryId,
        }));
    }

    get datasources() {
        return this._datasources;
    }

    get datasourcesData() {
        return this.instantData$.value;
    }

    constructor(
        public api: ApiService,
        private http: HttpClient,
        private translate: TranslateService,
    ) {
        this.init();
    }

    async init() {
        this._panes$
            .pipe(
                filter((v) => v != null),
                // Compare to previous value.
                filter((panes) => hash(panes) != this.lastPanesHash),
                tap((panes) => (this.lastPanesHash = hash(panes))),
            )
            .subscribe((panes: Pane[]) => this.panesUpdates$.next(panes));
    }

    /* Get the widget type name. */
    getWidgetTypeName(widget: Widget) {
        if (widget.type == WidgetType.CUSTOM_WIDGET) {
            return this._widgetTypeNames[widget.customWidgetId];
        }
        return this._widgetTypeNames[widget.type];
    }

    async setupDashboard(id) {
        log.info('setupDashboard');
        await waitForItem(this.api.dashboards, id);
        const dashboard = this.api.dashboards.get(id);
        this.dashboardId = dashboard.id;
        this.customerId = dashboard.customerId;
        this.name$.next(dashboard.name);
        this.numColumns$.next(dashboard.content.numColumns);

        this.context = await this.api.dashboards.context(id);

        // Load the widget type names.
        // TODO: move this to WidgetLoaderService
        const builtinWidgetTypes = Object.values(WidgetType)
            .filter((k) => k !== WidgetType.CUSTOM_WIDGET)
            .map((key) => [
                key as string,
                this.translate.instant(WidgetTypeName[key]),
            ]);
        const customWidgetTypes = Object.entries(this.context.widgets).map(
            ([id, widget]) => [id.toString(), widget.name],
        );
        this._widgetTypeNames = Object.fromEntries(
            builtinWidgetTypes.concat(customWidgetTypes),
        );

        this.disconnectFromDatasources(); // Disconnect from old datasources.
        this.setDatasources(
            await Promise.all(
                dashboard.content.datasources.map(
                    async (d) => await this.setupDatasource(d),
                ),
            ),
        );

        // TODO: load panes before datasources
        //       we can't do this yet, because a lot of the widgets load the
        //       datasource they want to send messages on when they first load
        log.info('initial panes set');
        this._panePositions = dashboard.content.panePositions;
        this._paneWidths = dashboard.content.paneWidths;
        // It is important to set this AFTER the positions and widths are set.
        this.setPanes(dashboard.content.panes);

        this.lastSavedHash = hash(this.dashboardData);

        // This is need in case a new device is added in a datasource.
        this.api.devices.listen().subscribe();

        this.connectDatasources();
    }

    async setupDatasource(config: DatasourceConfig) {
        const datasource = DatasourceFactory.createDatasource(config);
        if (datasource.type == DatasourceType.DEVICE) {
            const deviceId = datasource.settings.device;
            const hasDevice =
                this.context.devices.find((d) => d.id == deviceId) != undefined;
            if (!hasDevice && deviceId !== 0) {
                log.info('Loading device', deviceId);
                // A new device, so get the device and CAN databases.
                const device = this.api.devices.get(deviceId);
                this.context.devices.push(device);
                const canDatabases = await this.api.canDatabases.query({
                    modelId: device.modelId,
                    isActive: true,
                });
                this.context.canDatabases = this.context.canDatabases
                    .filter((db) => db.modelId != device.modelId)
                    .concat(canDatabases);
            }
        }
        datasource.data$.subscribe((datasourceData) =>
            this.addDatasourceData(datasource, datasourceData),
        );
        return datasource;
    }

    async connectDatasources() {
        for (const datasource of this.datasources) {
            await datasource.connect({
                dashboardPageService: this,
                busNames: this.busNumbers,
                devices: arrayToObject(this.context.devices, 'id'),
                httpClient: this.http,
                data$: this.data$,
                canDatabases: this.context.canDatabases,
            });
        }
        // This allows custom widgets to know about updated datasources.
        this.datasourceUpdates$.next(this.datasources);
    }

    addDatasourceData(
        { name, sandboxDatasource }: Datasource,
        newData: object,
    ) {
        const data = this.instantData$.getValue();
        if (data[name] == undefined) {
            data[name] = newData;
        } else if (newData['mon']) {
            Object.entries(newData['mon']).forEach(([bus, busData]) =>
                Object.entries(busData).forEach(([msg, msgData]) =>
                    updateNestedObject(data, [name, 'mon', bus, msg], msgData),
                ),
            );
        } else {
            updateNestedObject(data, [name], newData);
        }
        sandboxDatasource.newData(newData);
        this.instantData$.next(data);
    }

    disconnectFromDatasources() {
        this._datasources.forEach((d: Datasource) => d.disconnect());
    }

    async createNewPane() {
        const title = await this.translate
            .get('dashboard.new_pane')
            .toPromise();
        const newPane = new Pane({ title });
        // Get the y of the bottom pane in the left column.
        let y = Math.max(
            ...Array.from(this._panePositions.values())
                .filter((p) => p.x == 0)
                .map((p) => p.y),
        );
        // This is a hack. It would be better to use actual height of the bottom
        // pane.
        y += 1000;
        this.setPanePosition(newPane, { x: 0, y });
        this.setPaneWidth(newPane, 1);
        // Add the pane to the beginning of the list.
        this.setPanes([newPane, ...this._panes$.getValue()]);
        this.newPane$.next(newPane);
    }

    /* Trigger updating the panes. */
    updatePanes() {
        log.info('update panes');
        this.setPanes([...this._panes$.getValue()]);
    }

    deletePane(id: string) {
        this.setPanes(this._panes$.getValue().filter((p) => p.id !== id));
    }

    async addDatasource(datasource: Datasource) {
        await datasource.connect({
            dashboardPageService: this,
            busNames: this.busNumbers,
            devices: arrayToObject(this.context.devices, 'id'),
            httpClient: this.http,
            data$: this.data$,
            canDatabases: this.context.canDatabases,
        });
        this.setDatasources([...this._datasources, datasource]);
    }

    deleteDatasource(datasource: Datasource) {
        datasource.disconnect();
        this.setDatasources(
            this._datasources.filter((d) => d.uuid != datasource.uuid),
        );
    }

    async updateDatasource(datasource: Datasource, config: DatasourceConfig) {
        if (config.type != datasource.type) {
            log.error('Changing a datasource type is not supported!');
            return;
        }
        // Disconnect
        datasource.disconnect();
        // Merge settings
        config.settings = Object.assign(datasource.settings, config.settings);
        // Reconnect
        const updated = await this.setupDatasource(config);

        // Bring across the sandbox datasource
        updated.sandboxDatasource = datasource.sandboxDatasource;
        // Replace the old datasource
        this.setDatasources(
            this._datasources.map((d) =>
                d.uuid == datasource.uuid ? updated : d,
            ),
        );
    }

    saveChanges() {
        this.lastSavedHash = hash(this.dashboardData);
        this.api.dashboards.update(this.dashboardId, this.dashboardData);
    }

    getWidgetValueData(value) {
        const matches = value.match(/[^[\]"]+(?="])/gi);
        const [datasourceName, topic, widgetType] = matches;
        return { datasourceName, topic, widgetType };
    }

    findDatasourceByName(name) {
        return this._datasourcesByName[name];
    }

    findDatasourceByUuid(uuid) {
        return this._datasources.find((d) => d.uuid == uuid);
    }

    /**
     *  This method clear all you want to clear;
     *  Service doesn't destroy, you may want get rid of some side effects
     */
    clearObservables() {
        // Datasources
        this.setDatasources([]);
        this.datasourceUpdates$.next([]);
        // Panes
        log.info('clear panes');
        this.setPanes(null);
        this.newPane$.next(null);
        this._panes$.next([]);
        this.panesUpdates$.next([]);
        this._panePositions = new Map();
        this._paneWidths = new Map();
        // General
        this.dashboardId = null;
        this.customerId = null;
        this.deviceIds = [];
        this.name$.next('');
    }

    /* Getters and setters for private fields. */
    setPanes(panes: Pane[]) {
        this._panes$.next(panes);
    }
    getPanePosition(pane: Pane): Position {
        return this._panePositions.get(pane.id);
    }
    setPanePosition(pane: Pane, position: Position) {
        this._panePositions.set(pane.id, position);
    }
    getPaneWidth(pane: Pane): number {
        return this._paneWidths.get(pane.id);
    }
    setPaneWidth(pane: Pane, width: number) {
        this._paneWidths.set(pane.id, width);
    }
    setDatasources(datasources: Datasource[]) {
        const newHash = hash((datasources ?? []).map((d) => d.build()));
        if (newHash != this.lastDatasourcesHash) {
            const deviceIdSet = new Set();
            this._datasourcesByName = {};
            datasources.forEach((d) => {
                if (d.type === DatasourceType.DEVICE && d.settings.device) {
                    deviceIdSet.add(d.settings.device);
                }
                this._datasourcesByName[d.name] = d;
            });
            this.lastDatasourcesHash = newHash;
            this.deviceIds = Array.from(deviceIdSet);
            this._datasources = datasources;
            this.datasourceUpdates$.next(datasources);
        }
    }

    async sendToDatasource(path: string, value: string) {
        const parsedPath = parsePath(path);
        if (parsedPath) {
            const { topicPath, datasourceName } = parsedPath;
            const datasource = this.findDatasourceByName(datasourceName);
            if (!datasource) {
                throw Error(`Invalid datasource name: ${datasourceName}`);
            }
            const segments = parseSegments(topicPath);
            if (segments.length != 1) {
                throw Error(`Invalid publish topic path: ${topicPath}`);
            }
            await datasource.send(segments[0], value);
        } else {
            throw Error(`Invalid publish path: ${path}`);
        }
    }
}
